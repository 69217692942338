import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { Company, CompanyActivity, Establishment } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { Customer, NewCustomer } from '@dougs/customers/dto';
import { AllFields } from '@dougs/fields/dto';
import { Loan } from '@dougs/loans/dto';
import { Partner } from '@dougs/partners/dto';
import { NewSupplier, Supplier } from '@dougs/suppliers/dto';
import { Car, NewCar } from '@dougs/vehicles/dto';

@Injectable({
  providedIn: 'root',
})
export class FieldsHttpService {
  constructor(private readonly http: HttpClient) {}

  getCompanyFields(company: Company, groupIds?: string[]): Observable<AllFields> {
    return this.http.post<AllFields>(`${AppConfig.settings.legacyApiServerUrl}/companies/${company.id}/fields`, {
      company,
      groupIds: groupIds,
    });
  }

  getPartnerFields(partner: Partner, groupIds?: string[]): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${partner.companyId}/partners/${partner.id}/fields`,
      {
        partner,
        groupIds: groupIds,
      },
    );
  }

  getPartnerRoleFields(partner: Partner): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${partner.companyId}/partners/${partner.id}/roleFields`,
      {
        partner,
      },
    );
  }

  getCarFields(car: Car): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${car.companyId}/cars/${car.id}/fields`,
      {
        car,
      },
    );
  }

  getNewCarFields(companyId: number, car: NewCar): Observable<AllFields> {
    return this.http.post<AllFields>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/cars/fields`, {
      car,
    });
  }

  getLoanFields(loan: Loan): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${loan.companyId}/loans/${loan.id}/fields`,
      {
        loan,
      },
    );
  }

  getAccountingYearFields(accountingYear: AccountingYear, groupIds?: string[]): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${accountingYear.companyId}/accounting-years/${accountingYear.id}/fields`,
      {
        accountingYear,
        groupIds: groupIds || [],
      },
    );
  }

  getEstablishmentFields(companyId: number, establishment: Establishment): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/establishments/${establishment.id}/fields`,
      {
        establishment,
      },
    );
  }

  getCompanyActivityFields(companyActivity: CompanyActivity): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyActivity.companyId}/company-activities/${companyActivity.id}/fields`,
      {
        companyActivity,
      },
    );
  }

  getSupplierFields(supplier: Supplier): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${supplier.companyId}/suppliers/${supplier.id}/fields`,
      {
        supplier,
      },
    );
  }

  getNewSupplierFields(companyId: number, supplier: NewSupplier): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/suppliers/fields`,
      {
        supplier,
      },
    );
  }

  getCustomerFields(customer: Customer): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${customer.companyId}/customers/${customer.id}/fields`,
      {
        customer,
      },
    );
  }

  getNewCustomerFields(companyId: number, customer: NewCustomer): Observable<AllFields> {
    return this.http.post<AllFields>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/customers/fields`,
      {
        customer,
      },
    );
  }
}
