import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { AccountingYear } from '@dougs/accounting-years/dto';
import { Company, CompanyActivity, Establishment } from '@dougs/company/dto';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Customer, NewCustomer } from '@dougs/customers/dto';
import { AllFields, Fields, RoleFields } from '@dougs/fields/dto';
import { Loan } from '@dougs/loans/dto';
import { Partner } from '@dougs/partners/dto';
import { NewSupplier, Supplier } from '@dougs/suppliers/dto';
import { Car, NewCar } from '@dougs/vehicles/dto';
import { FieldsHttpService } from '../http/fields.http';

interface FieldsState {
  companyFields: AllFields;
  partnerFields: AllFields;
  partnerRoleFields: AllFields;
  ownerFields: AllFields;
  carFields: AllFields;
  loanFields: AllFields;
  accountingYearFields: Fields;
  establishmentFields: AllFields;
  currentAccountingYearFields: AllFields;
  companyActivityFields: AllFields;
  supplierFields: AllFields;
  customerFields: AllFields;
}

@Injectable({
  providedIn: 'root',
})
export class FieldsStateService extends StateService<FieldsState> {
  missingFields$: Observable<Fields> = this.select((state) => state.companyFields?.missingFields);
  ownerNaturalPersonFields$: Observable<Fields> = this.select((state) => state.ownerFields?.naturalPerson);
  partnerFields$: Observable<Fields> = this.select((state) => state.partnerFields?.partner);
  partnerRoleFields$: Observable<RoleFields> = this.select((state) => state.partnerRoleFields?.partnerRoles);
  partnerNaturalPersonFields$: Observable<Fields> = this.select((state) => state.partnerFields?.naturalPerson);
  partnerLegalPersonFields$: Observable<Fields> = this.select((state) => state.partnerFields?.legalPerson);
  partnerMissingFields$: Observable<Fields> = this.select((state) => state.partnerFields?.missingFields);
  companyFields$: Observable<Fields> = this.select((state) => state.companyFields?.company);
  carFields$: Observable<AllFields> = this.select((state) => state.carFields);
  carMissingFields$: Observable<Fields> = this.select((state) => state.carFields?.missingFields);
  loanFields$: Observable<AllFields> = this.select((state) => state.loanFields);
  loanMissingFields$: Observable<Fields> = this.select((state) => state.loanFields?.missingFields);
  accountingYearFields$: Observable<Fields> = this.select((state) => state.accountingYearFields);
  establishmentFields$: Observable<AllFields> = this.select((state) => state.establishmentFields);
  establishmentMissingFields$: Observable<Fields> = this.select((state) => state.establishmentFields?.missingFields);
  currentAccountingYearFields$: Observable<Fields> = this.select(
    (state) => state.currentAccountingYearFields?.accountingYear,
  );
  companyActivityFields$: Observable<AllFields> = this.select((state) => state.companyActivityFields);
  supplierFields$: Observable<AllFields> = this.select((state) => state.supplierFields);
  customerFields$: Observable<AllFields> = this.select((state) => state.customerFields);

  constructor(
    private readonly logger: LoggerService,
    private readonly fieldsHttpService: FieldsHttpService,
  ) {
    super();
  }

  async refreshCompanyFields(company: Company): Promise<void> {
    try {
      this.setState({
        companyFields: await lastValueFrom(this.fieldsHttpService.getCompanyFields(company)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async getCompanyFieldsByGroupIds(company: Company, groupIds: string[]): Promise<AllFields> {
    try {
      return await lastValueFrom(this.fieldsHttpService.getCompanyFields(company, groupIds));
    } catch (e) {
      this.logger.error(e);
      return {} as AllFields;
    }
  }

  async getPartnerFieldsByGroupIds(partner: Partner, groupIds: string[]): Promise<AllFields | null> {
    try {
      return await lastValueFrom(this.fieldsHttpService.getPartnerFields(partner, groupIds));
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  async refreshPartnerFields(partner: Partner): Promise<void> {
    try {
      this.setState({
        partnerFields: await lastValueFrom(this.fieldsHttpService.getPartnerFields(partner)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshPartnerRoleFields(partner: Partner): Promise<void> {
    try {
      this.setState({
        partnerRoleFields: await lastValueFrom(this.fieldsHttpService.getPartnerRoleFields(partner)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshOwnerFields(partner: Partner): Promise<void> {
    try {
      this.setState({
        ownerFields: await lastValueFrom(this.fieldsHttpService.getPartnerFields(partner)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshCarFields(car: Car): Promise<void> {
    try {
      this.setState({
        carFields: await lastValueFrom(this.fieldsHttpService.getCarFields(car)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshNewCarFields(companyId: number, car: NewCar): Promise<void> {
    try {
      this.setState({
        carFields: await lastValueFrom(this.fieldsHttpService.getNewCarFields(companyId, car)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshLoanFields(loan: Loan): Promise<void> {
    try {
      this.setState({
        loanFields: await lastValueFrom(this.fieldsHttpService.getLoanFields(loan)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshAccountingYearFields(accountingYear: AccountingYear, groupIds?: string[]): Promise<void> {
    try {
      this.setState({
        accountingYearFields: (
          await lastValueFrom(this.fieldsHttpService.getAccountingYearFields(accountingYear, groupIds))
        ).accountingYear,
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshCurrentAccountingYearFields(accountingYear: AccountingYear): Promise<void> {
    try {
      this.setState({
        currentAccountingYearFields: await lastValueFrom(
          this.fieldsHttpService.getAccountingYearFields(accountingYear),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshEstablishmentFields(companyId: number, establishment: Establishment): Promise<void> {
    try {
      this.setState({
        establishmentFields: await lastValueFrom(
          this.fieldsHttpService.getEstablishmentFields(companyId, establishment),
        ),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshCompanyActivityFields(companyActivity: CompanyActivity): Promise<void> {
    try {
      this.setState({
        companyActivityFields: await lastValueFrom(this.fieldsHttpService.getCompanyActivityFields(companyActivity)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshSupplierFields(supplier: Supplier): Promise<void> {
    try {
      this.setState({
        supplierFields: await lastValueFrom(this.fieldsHttpService.getSupplierFields(supplier)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshNewSupplierFields(companyId: number, supplier: NewSupplier): Promise<void> {
    try {
      this.setState({
        supplierFields: await lastValueFrom(this.fieldsHttpService.getNewSupplierFields(companyId, supplier)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshCustomerFields(customer: Customer): Promise<void> {
    try {
      this.setState({
        customerFields: await lastValueFrom(this.fieldsHttpService.getCustomerFields(customer)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshNewCustomerFields(companyId: number, customer: NewCustomer): Promise<void> {
    try {
      this.setState({
        customerFields: await lastValueFrom(this.fieldsHttpService.getNewCustomerFields(companyId, customer)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }
}
